/* global CarDeliveryListComponentB2C */
class OfiSnackCarDeliveryListComponentB2C extends CarDeliveryListComponentB2C {

    getTemplate() {
        return `<div class="col-12">
                  <template v-if="newDeliveryModal">
                    <addressCreateComponent />
                  </template>
                  <div class="col-12" >
                    <div class="mt-3 bordered reset-point" id="resetPoint">
                         <div class="row m-0 align-items-center">
                            <h6 class="col-12 col-lg-6 align-items-center d-flex"><i class="delivery-icon fas fa-address-card fa-2x mr-3"></i>{{tr('Select a Delivery Address')}}</h6>
                            <div class="col-12 col-lg-6 text-left text-lg-right">
                                <div class="my-2" v-on:click="openNewDelivery">
                                    <button type="button" class="btn btn-light" @click="openNewDeliveryModal">
                                        <i class="fas fa-plus mr-2"></i>
                                        {{tr('New Delivery Address')}}
                                   </button>
                                </div>
                            </div>
                        </div>
                        <div v-if="filterDeliveryList.length > 1" class="row m-0 align-items-start">
                            <div class="input-group col-12 col-lg-6">
                                <input class="form-control" v-model="searchText">
                                <div class="input-group-prepend">
                                  <div class="input-group-text"><i class="fa fa-search" /></div>
                                </div>
                            </div>
                        </div>
                        <div v-if="filterDeliveryList.length > 0" class="row m-0 px-2">
                             <template v-for="delObj in filterDeliveryList">
                                 <template v-if="delObj.Code==selectedCode">
                                    <div class="col-12 col-sm-6 col-lg-4 px-2">
                                        <div class="address-card my-2" :class="{selected:activeDeliveryAddress(delObj.Code)}" v-on:click="activeAddress(delObj.Code)" >
                                          <strong id="deliveryLabel"><i class="delivery-icon fas fa-map-marker-alt mr-1"></i>{{delObj.Name}}</strong>
                                          <p>{{delObj.Address}} </p>
                                          <p>{{delObj.Province}} </p>
                                          <p>{{delObj.City}} </p>
                                          <p>{{delObj.Locality}} </p>
                                          <p>{{delObj.ZipCode}} </p>
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <template v-for="delObj in filterDeliveryList">
                                <template v-if="delObj.Code!=selectedCode">
                                    <div class="col-12 col-sm-6 col-lg-4 px-2">
                                        <div class="address-card my-2" :class="{selected:activeDeliveryAddress(delObj.Code)}" v-on:click="activeAddress(delObj.Code)" >
                                          <strong id="deliveryLabel"><i class="delivery-icon fas fa-map-marker-alt mr-1"></i>{{delObj.Name}}</strong>
                                          <p>{{delObj.Address}} </p>
                                          <p>{{delObj.Province}} </p>
                                          <p>{{delObj.City}} </p>
                                          <p>{{delObj.Locality}} </p>
                                          <p>{{delObj.ZipCode}} </p>
                                        </div>
                                    </div>
                                </template>
                            </template>
                        </div>
                        <div class="row new-address-message text-left text-md-right m-0">
                            <p class="col-12 mb-1">Puede administrar las direcciones en <router-link to="/profile/Addresses">Perfil Direcciones</router-link></p>
                        </div>
                    </div>
                    <div class="bordered form-row m-0 mb-2 px-2" v-if="shipModes.length>0">
                        <h6 class="col-12 align-items-center d-flex mb-3"><i class="delivery-icon fas fa-truck fa-flip-horizontal fa-2x mr-3" style="font-size: 1.6em;"></i> {{tr('Ship Mode')}}</h6>
                        <template v-for="shipMode of shipModes" >
                            <div :key="'shipMode-'+shipMode.ShipModeCode" class="col-12 col-lg-6 px-2">
                                <div class="address-card row m-0 my-2" :class="{selected:selectedShipMode==shipMode.ShipModeCode}" v-on:click="selectedShipMode=shipMode.ShipModeCode">
                                  <div class="col-12" >
                                      <strong id="deliveryLabel"><i class="delivery-icon fas fa-truck fa-flip-horizontal mr-2"></i>{{shipMode.ShipModeName}}</strong>
                                      <p>
                                        {{tr('Cost without Taxes')}}:
                                        <template v-if="shipMode.FreeShipMinAmount || ($store.getters.getGiftCardApplied && $store.getters.getGiftCardApplied.NoShipCost) || ($store.getters.getCampaignApplied && $store.getters.getCampaignApplied.NoShipCost)">
                                            <template v-if="isFreeShipCost(shipMode.FreeShipMinAmount) || ($store.getters.getGiftCardApplied && $store.getters.getGiftCardApplied.NoShipCost) || ($store.getters.getCampaignApplied && $store.getters.getCampaignApplied.NoShipCost)">
                                                 <del class="text-warning">$ {{shipMode.Cost }}</del> <strong class="text-success">Costo Bonificado</strong>
                                            </template>
                                            <template v-else>
                                                $ {{shipMode.Cost }}
                                            </template>
                                        </template>
                                        <template v-else>
                                            $ {{shipMode.Cost }}
                                        </template>
                                      </p>
                                      <p>
                                          <template v-if="shipMode.OfficeCode">
                                                Retiro en Sucursal {{shipMode.OfficeName}}
                                          </template>
                                          <template v-else>
                                                <template v-if="shipMode.DelayMinDays && shipMode.DelayMaxDays">
                                                    Llega entre {{calculateDate(shipMode.DelayMinDays)}} y el {{calculateDate(shipMode.DelayMaxDays)}}.
                                                </template>
                                          </template>
                                      </p>
                                      <template v-if="shipMode.FreeShipMinAmount>0 && shipMode.FreeShipMinAmount!=1010000000">
                                        <p>Envio gratis para compras mayores de $ {{shipMode.FreeShipMinAmount}}.</p>
                                      </template>
                                  </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <div class="bordered form-row m-0 mb-2 px-2">
                        <h6 class="col-12 align-items-center d-flex mb-3"><i class="delivery-icon fas fa-truck fa-flip-horizontal fa-2x mr-3" style="font-size: 1.6em;"></i> {{tr('Reception Details')}}</h6>
                        <template v-if="activeDayByZone">
                            <div class="form-group col-12 col-md-6  align-self-start mb-0" >
                                <label for="inputEmail4">{{tr('Day')}}</label>
                                <select class="custom-select mb-3" v-on:change="updateSelectValues('DelDay')">
                                    <option value="null" selected>{{tr('Select One')}}</option>
                                    <option value="1" v-if="daysByZone.Monday">{{tr('Monday')}}</option>
                                    <option value="2" v-if="daysByZone.Tuesday">{{tr('Tuesday')}}</option>
                                    <option value="3" v-if="daysByZone.Wednesday">{{tr('Wednesday')}}</option>
                                    <option value="4" v-if="daysByZone.Thursday">{{tr('Thursday')}}</option>
                                    <option value="5" v-if="daysByZone.Friday">{{tr('Friday')}}</option>
                                    <option value="6" v-if="daysByZone.Saturday" >{{tr('Saturday')}}</option>
                                    <option value="7" v-if="daysByZone.Sunday">{{tr('Sunday')}}</option>
                                </select>
                            </div>
                            <div class="col-md-6"></div>
                        </template>
                        <div class="form-row col-12 col-md-6 mb-0" :class="{'d-none':!showCommentSt}">
                           <label>Aclaraciones sobre el domicilio</label>
                            <textarea maxlength="195" class="form-control" v-model="commentSt" rows="3" max placeholder="Observaciones del domicilio, entre calles, otras referencias…" @change="buildComment" ></textarea>
                        </div>
                        <div class="form-group col-12 col-md-6 mb-0" :class="{'d-none':!showCommentSO}">
                           <label>Aclaraciones sobre el Pedido</label>
                           <textarea maxlength="195" class="form-control" v-model="commentSO" rows="3" placeholder="Observaciones del pedido, encargado de recepción, etc…" @change="buildComment" ></textarea>
                        </div>
                    </div>
                  </div>
            </div>`;
    }
}

OfiSnackCarDeliveryListComponentB2C.registerComponent();